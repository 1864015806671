import React, { Component, useReducer } from "react";
import "./Login.css";
import app from "../../firebase";
import { withRouter, Link } from "react-router-dom";
import error from "../../assets/error.svg";
import { AuthContext } from "./Auth";

class Login extends Component {
  static contextType = AuthContext;

  state = {
    email: "",
    password: "",
    errorMessage: "",
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.onLogin();
  };

  onLogin = async () => {
    try {
      await app
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password);

      let currentUser = this.context.currentUser;

      this.props.history.push(`/app/${currentUser.uid}`);
    } catch (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  render() {
    return (
      <section className="login-section">
        <div className="login-container">
          <div className="login-form-container">
            <h1 className="login-form-title">Login</h1>
            {/* <p className="login-form-subtitle">
              This is just an explantion of the above. This is just an
              explantion of the above
            </p> */}
            {this.state.errorMessage && (
              <div className="login-error-container">
                <img className="error-icon" src={error} />
                <h3>{this.state.errorMessage}</h3>
              </div>
            )}
            <form onSubmit={this.handleSubmit} className="login-form-input">
              <label className="login-email-label">Email</label>
              <input
                onChange={this.handleEmailChange}
                value={this.state.email}
                type="text"
                className="login-email-input"
                placeholder="you@example.com"
              ></input>
              <label className="login-password-label">Password</label>
              <input
                onChange={this.handlePasswordChange}
                value={this.state.password}
                type="password"
                className="login-password-input"
                placeholder="Password"
              ></input>

              <button type="submit" className="login-submit-button">
                <span>Sign in</span>
              </button>
            </form>
          </div>
          <div className="login-register-button">
            <p>
              Don't have an account?
              <a href="/signup" className="register-button-redirect">
                Sign Up
              </a>
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default Login;
