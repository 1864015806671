import React, { Component } from "react";
import Ama from "./Ama";
import "./Form.css";
import { useParams } from "react-router-dom";
import successTick from "../../assets/success-tick.svg";

class Form extends Component {
  // baseUrl = "http://localhost:4000";
  baseUrl = "https://ama-server-v1.herokuapp.com";

  state = {
    value: "",
    userId: this.props.match.params.userId,
    successMessage: "",
  };

  handleInputChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    fetch(`${this.baseUrl}/${this.state.userId}/questions/dashboard`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(this.state),
    });

    this.setState({ value: "" });

    this.setState({
      successMessage: "Submitted! Make sure to check back for the answer!",
    });
  };

  render() {
    return (
      <div className="root">
        <div className="container">
          <h1 className="title">Ask Me Something</h1>
          <h3 className="subtext">
            Questions will be visible once they are answered.
          </h3>
          <form className="question-form" onSubmit={this.handleSubmit}>
            <textarea
              required
              className="textarea-form"
              value={this.state.value}
              onChange={this.handleInputChange}
            ></textarea>
            {this.state.successMessage && (
              <div className="success-message">
                <img className="success-image" src={successTick} />
                <p className="success-message-text">
                  {this.state.successMessage}
                </p>
              </div>
            )}
            <button className="submit-button" type="submit">
              <span className="button-text">Submit</span>
            </button>
          </form>
          <div className="divider"></div>
          <Ama userId={this.state.userId} />
        </div>
      </div>
    );
  }
}

export default Form;
