import React, { Component } from "react";
import "./QuestionAnswered.css";
import { format } from "timeago.js";

class QuestionAnswered extends Component {
  render() {
    return (
      <div className="answers-full-container">
        <div className="answers-top-box">
          <div className="answers-badge">
            <h3 className="answers-badge-text">ANSWERED</h3>
          </div>
          <div className="answers-question" key={this.props.id}>
            <p className="answers-question-text">{this.props.question}</p>
            <p className="answers-answer">{this.props.answer}</p>
          </div>
        </div>
        <div className="answers-bottom-box">
          <div className="answers-createdAt">
            <h3 className="answers-received-text">SENT</h3>
            <h3 className="answers-date-text">{format(this.props.date)}</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionAnswered;
