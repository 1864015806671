import React, { Component, useContext } from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import Embed from "./Embed";
import Pricing from "./Pricing";
import Footer from "./Footer";

class HomePage extends Component {
  state = {};

  render() {
    return (
      <div>
        <NavBar />
        <Hero />
        <HowItWorks />
        <Embed />
        <Pricing />
        <Footer />
      </div>
    );
  }
}

export default HomePage;
