import React, { Component } from "react";
import "./Success.css";
import successIcon from "../../assets/success-payment-icon.svg";
import { AuthContext } from "../authentication/Auth";

class PaymentSuccess extends Component {
  state = {};

  static contextType = AuthContext;

  render() {
    let currentUser = this.context.currentUser;
    return (
      <section className="success-section">
        <div className="success-container">
          <img src={successIcon} />
          <span className="success-page-title">Payment Successful!</span>
          <span className="success-page-subtitle">
            Your subscription has been created. Navigate to your dashboard and
            start answering questions!
          </span>
          <a href={`/app/${currentUser.uid}`}>
            <button className="success-page-button">
              Go to your dashboard
            </button>
          </a>
        </div>
      </section>
    );
  }
}

export default PaymentSuccess;
