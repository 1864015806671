import React, { Component } from "react";
import "./Error.css";
import errorIcon from "../../assets/error-payment-icon.svg";

class Error extends Component {
  state = {};
  render() {
    return (
      <section className="error-section">
        <div className="error-container">
          <img src={errorIcon} />
          <span className="error-page-title">Oops, there was an error!</span>
          <span className="error-page-subtitle">
            It seems like there was an error. Head to the homepage and give it
            another try!
          </span>
          <a href="/">
            <button className="error-page-button">Go back to home</button>
          </a>
        </div>
      </section>
    );
  }
}

export default Error;
