import React, { Component } from "react";
import "./HowItWorks.css";
import featureOne from "../../assets/featureOne.png";
import featureTwo from "../../assets/featureTwo.png";
import featureThree from "../../assets/featureThree.png";
import linkIcon from "../../assets/hiw-link.svg";
import shareIcon from "../../assets/hiw-share.svg";

class HowItWorks extends Component {
  state = {};
  render() {
    return (
      <section className="hiw-section">
        <div className="hiw-container">
          <div className="hiw-title-container">
            <h3 className="hiw-title">How It Works</h3>
            <p className="hiw-subtitle">
              Nothing complicated. A simple tool with a simple use.
            </p>
          </div>
          <div className="feature">
            <div className="feature-text-container">
              <div className="hiw-number">
                <h3 className="hiw-number-text">1</h3>
              </div>
              <h3 className="hiw-feature-title">
                Copy and share your personal link
              </h3>
              <p className="hiw-feature-subtitle">
                Share a link to your personal dashboard, so anyone can ask you a
                question. You can also embed it to your personal website and
                make it your own.
              </p>
              <div className="extraFeatures-container hiw-first">
                <div className="extraFeatures-image-container ">
                  <img src={linkIcon} />
                </div>
                <h3 className="extraFeatures-text">
                  Embed on your personal website
                </h3>
              </div>
              <div className="extraFeatures-container hiw-second">
                <div className="extraFeatures-image-container">
                  <img src={shareIcon} />
                </div>
                <h3 className="extraFeatures-text">Share a public link</h3>
              </div>
            </div>
            <div className="feature-image-container">
              <img src={featureOne} />
            </div>
          </div>
          <div className="feature hiw-swapped">
            <div className="feature-image-container">
              <img src={featureTwo} />
            </div>
            <div className="feature-text-container">
              <div className="hiw-number">
                <h3 className="hiw-number-text">2</h3>
              </div>
              <h3 className="hiw-feature-title">Collect questions</h3>
              <p className="hiw-feature-subtitle hiw-space-feature">
                Start collecting questions from your followers instantly and let
                them get to know you better.
              </p>
            </div>
          </div>
          <div className="feature">
            <div className="feature-text-container">
              <div className="hiw-number">
                <h3 className="hiw-number-text">3</h3>
              </div>
              <h3 className="hiw-feature-title">Receive and answer</h3>
              <p className="hiw-feature-subtitle hiw-space-feature">
                Once you have received and answered the questions, they will
                automatically be published to your public dashboard, without you
                having to do anything.
              </p>
            </div>
            <div className="feature-image-container">
              <img src={featureThree} />
            </div>
          </div>

          <div></div>
          <div></div>
        </div>
      </section>
    );
  }
}

export default HowItWorks;
