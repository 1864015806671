import React from "react";
import Form from "./components/ama/Form";
import Dashboard from "./components/dashboard/Dashboard";
import HomePage from "./components/homepage/HomePage";
import Success from "./components/payment_message/Success";
import Error from "./components/payment_message/Error";
import { Route, Switch, Redirect } from "react-router-dom";
import { AuthProvider } from "./components/authentication/Auth";
import PrivateRoute from "./components/authentication/PrivateRoute";
import Login from "./components/authentication/Login";
import Register from "./components/authentication/Register";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-198451961-1";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview("/");

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Switch>
          <Route exact path="/" component={HomePage}></Route>
          <Route exact path="/signup" component={Register}></Route>
          <Route exact path="/login" component={Login}></Route>
          <PrivateRoute
            exact
            path="/app/:userId"
            component={Dashboard}
          ></PrivateRoute>
          <Route exact path="/ama/:userId" component={Form}></Route>
          <Route exact path="/demo" component={Form}></Route>
          <Route exact path="/success" component={Success}></Route>
          <Route exact path="/error" component={Error}></Route>
          <Route render={() => <Redirect to={{ pathname: "/error" }} />} />
        </Switch>
      </div>
    </AuthProvider>
  );
}

export default App;
