import React, { Component } from "react";
import "./Ama.css";
import image from "../../assets/Undraw.png";
import greyLogo from "../../assets/grey-logo.svg";
import { useParams } from "react-router-dom";
import { format } from "timeago.js";
import ClipLoader from "react-spinners/ClipLoader";

class Ama extends Component {
  // baseUrl = "http://localhost:4000";
  baseUrl = "https://ama-server-v1.herokuapp.com";

  state = {
    questionAnswer: [],
    userId: this.props.userId,
    loading: true,
  };

  fetchData = () => {
    fetch(`${this.baseUrl}/${this.state.userId}/answers/ama`)
      .then((response) => response.json())
      .then((data) => this.setState({ loading: false, questionAnswer: data }));
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div className="loading-wrapper">
            <ClipLoader
              size={35}
              color={"#1a202c"}
              loading={this.state.loading}
              speedMultiplier={1.5}
            ></ClipLoader>
          </div>
        ) : (
          <div>
            {this.state.questionAnswer.length > 0 ? (
              <div className="answers-containers">
                {this.state.questionAnswer.map((item) => (
                  <div className="answer-box" key={item._id}>
                    <h2 className="question">{item.question}</h2>
                    <p className="answer">{item.answer}</p>
                    <p className="createdAt">{format(item.createdAt)}</p>
                  </div>
                ))}

                <div className="logo-stamp-container">
                  <h3 className="powered-text">Powered by:</h3>
                  <div className="stamp-container">
                    <img src={greyLogo} />
                    <a
                      href="https://askmesomething.io"
                      target="_blank"
                      className="logo-stamp"
                    >
                      Ask Me Something
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div className="emptystate-container">
                <img className="tick" src={image} />
                <h3 className="empty-title">No answers yet...</h3>
                <p className="empty-subtitle">
                  The user hasn’t answered any questions yet, but we are working
                  on it!
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Ama;
