import React, { Component, useContext } from "react";
import "./Hero.css";
import imageAma from "../../assets/imageAma.png";
import amaMobile from "../../assets/ama-mobile.png";

import { AuthContext } from "../authentication/Auth";

class Hero extends Component {
  state = {};

  static contextType = AuthContext;

  render() {
    let currentUser = this.context.currentUser;
    return (
      <section className="hero-section">
        <div className="hero-container">
          <div className="hero-title-container">
            <h1 className="hero-title">
              Create a personal AMA dashboard in a few clicks
            </h1>
            <p className="hero-subtitle">
              A more effective, engaging way to connect to the people who follow
              you.
            </p>
          </div>
          <div className="hero-button-container">
            <a target="_blank" href="/ama/9I0nQ57UedTDBLIPFsfgr89J05B3">
              <button className="hero-button-left">
                <span>See Demo</span>
              </button>
            </a>
            {!currentUser ? (
              <a href="/signup">
                <button className="hero-button-right">
                  <span>Sign Up For Free</span>
                </button>
              </a>
            ) : (
              <a href={`/app/${currentUser.uid}`}>
                <button className="hero-button-right">
                  <span>Go To Dashboard</span>
                </button>
              </a>
            )}
          </div>
          <div className="hero-image">
            <div className="image-top-container">
              <div className="menu-buttons">
                <div className="button-red"></div>
                <div className="button-yellow"></div>
                <div className="button-green"></div>
              </div>
              <div className="search-bar">
                <span className="domain-text-one">askmesomething.io/ama/</span>
                <span className="domain-text-two">yourdashboard</span>
              </div>
            </div>
            <div className="image-bottom-container">
              <img className="amaMobile" src={amaMobile} />
              <img className="amaDesktop" src={imageAma} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;
