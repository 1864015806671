import firebase from "firebase/app";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyCy38TzHFJ0TnXKkh95wc5QRhuHFg_VZJs",
  authDomain: "ama-project-ecce3.firebaseapp.com",
  projectId: "ama-project-ecce3",
  storageBucket: "ama-project-ecce3.appspot.com",
  messagingSenderId: "54056421845",
  appId: "1:54056421845:web:b343391ff7a8802e78d55c",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();

export default app;
export { auth };
