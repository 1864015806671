import React, { Component } from "react";
import "./NavBar.css";
import companyLogo from "../../assets/companyLogo.svg";
import arrowLeft from "../../assets/arrow-left.svg";
import { AuthContext } from "../authentication/Auth";

class NavBar extends Component {
  state = {};

  static contextType = AuthContext;

  render() {
    let currentUser = this.context.currentUser;

    return (
      <section className="nav-section">
        <div className="nav-container">
          <div className="nav-inner-container">
            <a className="return-home" href="/">
              <div className="nav-left">
                <img src={companyLogo} />
                <a className="nav-brand-name">Ask Me Something</a>
              </div>
            </a>
            <div className="nav-right">
              <a href="#embed" className="nav-button ">
                Widget
              </a>
              <a href="#pricing" className="nav-button ">
                Pricing
              </a>
              {!currentUser ? (
                <a href="/login" className="nav-button ">
                  <div className="nav-sign-wrapper">
                    Login
                    <img src={arrowLeft} />
                  </div>
                </a>
              ) : (
                <a href={`/app/${currentUser.uid}`} className="nav-button">
                  <div className="nav-sign-wrapper">
                    Login
                    <img src={arrowLeft} />
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default NavBar;
