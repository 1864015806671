import React, { useEffect, useState, createContext } from "react";
import app from "../../firebase";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(true);
  const [userToken, setUserToken] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    app.auth().onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        user.getIdToken(true).then((token) => setUserToken(token));
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <div></div>;
  }

  return (
    <AuthContext.Provider value={{ currentUser, userToken }}>
      {children}
    </AuthContext.Provider>
  );
};
