import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./Embed.css";
import arrowIcon from "../../assets/arrowIcon.svg";
import clipboard from "../../assets/clipboard.svg";
import clipSuccess from "../../assets/clip-success.svg";

class Embed extends Component {
  state = {
    copySuccessful: false,
  };

  render() {
    return (
      <section id="embed" className="embed-section">
        <div className="embed-container">
          <div className="embed-title-container">
            <h3 className="embed-title">
              Embed easily as a widget on your website
            </h3>
            <p className="embed-subtitle">
              Less than 5 lines of code for a better engagement with your
              website visitors.
            </p>
          </div>
          <div className="embed-code-fullContainer">
            <div className="outside-overflow">
              <CopyToClipboard
                text={`<script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.1/iframeResizer.min.js"></script>
              <iframe src="https://askmesomething.io/ama/9I0nQ57UedTDBLIPFsfgr89J05B3" id="ama-dashboard" frameborder="0" scrolling="no" width="100%"></iframe>
              <script type="text/javascript">iFrameResize({log: false, checkOrigin: false}, "#ama-dashboard");</script>
              `}
              >
                <div className="clip-wrapper">
                  <img
                    onClick={() => this.setState({ copySuccessful: true })}
                    src={this.state.copySuccessful ? clipSuccess : clipboard}
                  />
                </div>
              </CopyToClipboard>
            </div>
            <div className="embed-code-container">
              <div className="embed-code-line">
                <span className="code-line-number">1</span>
                <span className="code-line-comment">&lt;!--HTML--&gt;</span>
              </div>
              <div className="embed-code-line">
                <span className="code-line-number">2</span>
                <span className="code-line-bracket">&lt;</span>
                <span className="code-line-script">script</span>
                <span className="code-line-source">type</span>
                <span className="code-line-bracket">=</span>
                <span className="code-line-link">"text/javascript"</span>
                <span className="code-line-source left-margin">src</span>
                <span className="code-line-bracket">=</span>
                <span className="code-line-link">
                  "https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.1/iframeResizer.min.js"
                </span>
                <span className="code-line-bracket">&gt;</span>
                <span className="code-line-bracket">&lt;</span>
                <span className="code-line-bracket">/</span>
                <span className="code-line-script">script</span>
                <span className="code-line-bracket">&gt;</span>
              </div>
              <div className="embed-code-line">
                <span className="code-line-number">3</span>
                <span className="code-line-bracket">&lt;</span>
                <span className="code-line-script">iframe</span>
                <span className="code-line-source ">src</span>
                <span className="code-line-bracket">=</span>
                <span className="code-line-link">
                  "https://askmesomething.io/ama/9I0nQ57UedTDBLIPFsfgr89J05B3"
                </span>
                <span className="code-line-source left-margin">id</span>
                <span className="code-line-bracket">=</span>
                <span className="code-line-link">"ama-dashboard"</span>
                <span className="code-line-source left-margin">
                  frameborder
                </span>
                <span className="code-line-bracket">=</span>
                <span className="code-line-link">"0"</span>
                <span className="code-line-source left-margin">scrolling</span>
                <span className="code-line-bracket">=</span>
                <span className="code-line-link">"no"</span>
                <span className="code-line-source left-margin">width</span>
                <span className="code-line-bracket">=</span>
                <span className="code-line-link">"100%"</span>
                <span className="code-line-bracket">&gt;</span>
                <span className="code-line-bracket">&lt;</span>
                <span className="code-line-bracket">/</span>
                <span className="code-line-script">iframe</span>
                <span className="code-line-bracket">&gt;</span>
              </div>

              <div className="embed-code-line">
                <span className="code-line-number">4</span>
                <span className="code-line-bracket">&lt;</span>
                <span className="code-line-script">script</span>
                <span className="code-line-source">type</span>
                <span className="code-line-bracket">=</span>
                <span className="code-line-link">"text/javascript"</span>
                <span className="code-line-bracket">&gt;</span>
                <span className="code-line-function">iFrameResize</span>
                <span className="code-line-bracket">{"("} </span>
                <span className="code-line-bracket">{"{"} </span>
                <span className="code-line-key">log: </span>
                <span className="code-line-link">false, </span>
                <span className="code-line-key">checkOrigin: </span>
                <span className="code-line-link">false </span>
                <span className="code-line-bracket">{"},"} </span>
                <span className="code-line-link">"#ama-dashboard"</span>
                <span className="code-line-bracket">{");"} </span>
                <span className="code-line-bracket">&lt;</span>
                <span className="code-line-bracket">/</span>
                <span className="code-line-script">script</span>
                <span className="code-line-bracket">&gt;</span>
              </div>
            </div>
          </div>
          <a
            href="https://viktoratanasov.com/ama.html"
            target="_blank"
            className="embed-demo-text"
          >
            <div className="embed-demo-button">
              View Widget Demo
              <img className="arrowIcon" src={arrowIcon} />
            </div>
          </a>
        </div>
      </section>
    );
  }
}

export default Embed;
