import React, { Component } from "react";
import { useParams, Link, Redirect } from "react-router-dom";
import app from "firebase/app";
import "./Dashboard.css";
import imageLink from "../../assets/link-icon.png";
import imageShare from "../../assets/share-icon.png";
import companyLogo from "../../assets/companyLogo.svg";
import cross from "../../assets/cross.svg";
import warningIcon from "../../assets/warning-icon.svg";
import { AuthContext } from "../authentication/Auth";
import copyrightLogo from "../../assets/copyright-logo.svg";
import QuestionAsked from "./QuestionAsked";
import QuestionAnswered from "./QuestionAnswered";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import paywallCheck from "../../assets/paywall-check.svg";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(
  "pk_live_51IqciUC1dUkjo05tFjUdc3IR4Tl78zUTyBIMfJsfQdB8GfEHbGCGjdbXxptQ81Ow7nnkJiOih6l7bHFeorcNjwaW00Uoe6Tew6"
);

class Dashboard extends Component {
  static contextType = AuthContext;
  baseUrl = "https://ama-server-v1.herokuapp.com";
  // baseUrl = "http://localhost:4000";

  state = {
    questions: [],
    answers: [],
    userId: this.props.match.params.userId,
    placeholderQuestion: {
      question:
        "This is how your questions are going to look once your receive. To get started feel free to put the widget on your website or just share your public page. Hope you like it, right?",
    },
    showQuestions: true,
    showModal: false,
    copySuccessful: false,
    loading: true,
    paywall: false,
    paidUser: false,
    cancel_subscripiton_modal: false,
    deleteQuestionModal: { show: false, question: {} },
  };

  componentDidMount() {
    this.refresh();
  }

  // Stripe

  cancelSubscription = async () => {
    fetch(`${this.baseUrl}/cancel-subscription`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        firebaseId: this.state.userId,
        idToken: this.context.userToken,
      }),
    })
      .then((response) => response.json())
      .then((data) =>
        this.setState({ paidUser: false, cancel_subscripiton_modal: false })
      );
  };

  createCheckoutSession = async () => {
    const stripe = await stripePromise;

    const response = await fetch(`${this.baseUrl}/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        priceId: "price_1IxBqUC1dUkjo05tTLvQBTyI",
        idToken: this.context.userToken,
      }),
    });

    const session = await response.json();

    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
  };

  refresh = () => {
    Promise.all([
      fetch(`${this.baseUrl}/${this.state.userId}/questions/dashboard`)
        .then((response) => response.json())
        .then((data) =>
          this.setState({
            loading: false,
            questions: data.result,
            paywall: data.paywall,
            paidUser: data.paidUser,
          })
        ),

      fetch(`${this.baseUrl}/${this.state.userId}/answers/ama`)
        .then((response) => response.json())
        .then((data) => this.setState({ answers: data })),
    ]);
  };

  deleteQuestionModal = (question) => {
    this.setState({ deleteQuestionModal: { show: true, question: question } });
  };

  deleteQuestion = () => {
    fetch(`${this.baseUrl}/delete-question`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        question: this.state.deleteQuestionModal.question,
        idToken: this.context.userToken,
      }),
    }).then(() => {
      this.setState({ deleteQuestionModal: { show: false } });
      this.refresh();
    });
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
    this.setState({ copySuccessful: false });
  };

  signOut = () => {
    app.auth().signOut();
  };

  showQuestions = (e) => {
    e.preventDefault();
    this.setState({ showQuestions: true });
  };

  showAnswers = (e) => {
    e.preventDefault();
    this.setState({ showQuestions: false });
  };

  render() {
    return (
      <div className="dashboard-root">
        {this.context.currentUser.uid !== this.state.userId && (
          <Redirect to="/error" />
        )}
        {this.state.cancel_subscripiton_modal && (
          <DialogOverlay>
            <DialogContent>
              <div className="cancel_subscription_modal">
                <div className="warning_icon_wrapper">
                  <img src={warningIcon} />
                </div>
                <span className="subscription_modal_title">
                  Are you sure you want to cancel?
                </span>
                <span className="subscription_modal_subtitle">
                  Cancelling your subscription would mean that you won't be able
                  to asnwer the questions you receive.
                </span>
                <button
                  onClick={() =>
                    this.setState({ cancel_subscripiton_modal: false })
                  }
                  className="subscription_modal_buttonOne"
                >
                  I would like to keep my subscription
                </button>
                <button
                  onClick={this.cancelSubscription}
                  className="subscription_modal_buttonTwo"
                >
                  Yes, I would like to cancel
                </button>
              </div>
            </DialogContent>
          </DialogOverlay>
        )}
        {this.state.paywall && (
          <DialogOverlay>
            <DialogContent>
              <div className="paywall-modal-container">
                <div className="paywall-top">
                  <div className="paywall-badge">PRO</div>
                  <div className="paywall-modal-price">
                    $5
                    <span>/month</span>
                  </div>
                  <div className="paywall-modal-subtext">
                    You have reached your limit! Upgrade now to get unlimited
                    submissions and stay connected to your audience.
                  </div>
                </div>
                <div className="paywall-bottom">
                  <div className="paywall-modal-item">
                    <img src={paywallCheck} />
                    <span>Unlimited question and answer submissions</span>
                  </div>
                  <button onClick={this.createCheckoutSession} row="link">
                    Upgrade Subscription
                  </button>
                  <div className="paywall-cancel-text">Powered by Stripe</div>
                </div>
              </div>
            </DialogContent>
          </DialogOverlay>
        )}
        <DialogOverlay
          className="embed-overlay"
          isOpen={this.state.showModal}
          onDismiss={this.closeModal}
        >
          <DialogContent>
            <div className="modal-top-container">
              <img
                onClick={this.closeModal}
                className="crossIcon"
                src={cross}
              />
            </div>
            <div className="modal-bottom-container">
              <h1>Embed Widget</h1>
              <p>
                To embed your AMA dashboard on your website, just add the
                following 3 lines of code to your HTML. If you have any troubles
                with the integration just
                <a
                  className="modal-contact-support"
                  href="mailto:viktor_atanasov@icloud.com"
                >
                  <span>shoot us an email</span>
                </a>
                and we will sort it out!
              </p>

              <div className="modal-code-container">
                <div className="embed-code-line">
                  <span className="code-line-number">1</span>
                  <span className="code-line-comment">&lt;!--HTML--&gt;</span>
                </div>
                <div className="embed-code-line">
                  <span className="code-line-number">2</span>
                  <span className="code-line-bracket">&lt;</span>
                  <span className="code-line-script">script</span>
                  <span className="code-line-source">type</span>
                  <span className="code-line-bracket">=</span>
                  <span className="code-line-link">"text/javascript"</span>
                  <span className="code-line-source left-margin">src</span>
                  <span className="code-line-bracket">=</span>
                  <span className="code-line-link">
                    "https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.1/iframeResizer.min.js"
                  </span>
                </div>
                <div className="embed-code-line">
                  <span className="code-line-number">3</span>
                  <span className="code-line-bracket">&lt;</span>
                  <span className="code-line-script">iframe</span>
                  <span className="code-line-source ">src</span>
                  <span className="code-line-bracket">=</span>
                  <span className="code-line-link">
                    "{`https://askmesomething.io/ama/${this.state.userId}`}"
                  </span>
                  <span className="code-line-source left-margin">id</span>
                  <span className="code-line-bracket">=</span>
                  <span className="code-line-link">"ama-dashboard"</span>
                  <span className="code-line-source left-margin">
                    frameborder
                  </span>
                  <span className="code-line-bracket">=</span>
                  <span className="code-line-link">"0"</span>
                  <span className="code-line-source left-margin">
                    scrolling
                  </span>
                  <span className="code-line-bracket">=</span>
                  <span className="code-line-link">"no"</span>
                  <span className="code-line-source left-margin">width</span>
                  <span className="code-line-bracket">=</span>
                  <span className="code-line-link">"100%"</span>
                  <span className="code-line-bracket">&gt;</span>
                  <span className="code-line-bracket">&lt;</span>
                  <span className="code-line-bracket">/</span>
                  <span className="code-line-script">iframe</span>
                  <span className="code-line-bracket">&gt;</span>
                </div>
                <div className="embed-code-line">
                  <span className="code-line-number">4</span>
                  <span className="code-line-bracket">&lt;</span>
                  <span className="code-line-script">script</span>
                  <span className="code-line-source">type</span>
                  <span className="code-line-bracket">=</span>
                  <span className="code-line-link">"text/javascript"</span>
                  <span className="code-line-bracket">&gt;</span>
                  <span className="code-line-function">iFrameResize</span>
                  <span className="code-line-bracket">{"("} </span>
                  <span className="code-line-bracket">{"{"} </span>
                  <span className="code-line-key">log: </span>
                  <span className="code-line-link">false, </span>
                  <span className="code-line-key">checkOrigin: </span>
                  <span className="code-line-link">false </span>
                  <span className="code-line-bracket">{"},"} </span>
                  <span className="code-line-link">"#ama-dashboard"</span>
                  <span className="code-line-bracket">{");"} </span>
                  <span className="code-line-bracket">&lt;</span>
                  <span className="code-line-bracket">/</span>
                  <span className="code-line-script">script</span>
                  <span className="code-line-bracket">&gt;</span>
                </div>
              </div>
              <div className="modal-button-wrapper">
                <button className="modal-button-left" onClick={this.closeModal}>
                  <span>Cancel</span>
                </button>
                <CopyToClipboard
                  text={`<script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.1/iframeResizer.min.js"></script>
              <iframe src="https://askmesomething.io/ama/${this.state.userId}" id="ama-dashboard" frameborder="0" scrolling="no" width="100%"></iframe>
              <script type="text/javascript">iFrameResize({log: false, checkOrigin: false}, "#ama-dashboard");</script>
              `}
                >
                  <button
                    onClick={() => this.setState({ copySuccessful: true })}
                    className="modal-button-right"
                  >
                    <span>
                      {this.state.copySuccessful ? "Copied" : "Copy Code"}
                    </span>
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </DialogContent>
        </DialogOverlay>
        {this.state.deleteQuestionModal.show && (
          <DialogOverlay>
            <DialogContent>
              <div className="deleteQuestion-modal-wrapper">
                Are you sure you want to delete this question?
                <span>
                  Deleting this question will mean that you won't able to answer
                  it later and it will be deleted from your dashboard.
                </span>
                <div className="deleteQuestion-modal-buttonWrapper">
                  <button
                    onClick={() =>
                      this.setState({
                        deleteQuestionModal: { show: false },
                      })
                    }
                    className="deleteQuestion-modal-cancelButton"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={this.deleteQuestion}
                    className="deleteQuestion-modal-deleteButton"
                  >
                    Delete Question
                  </button>
                </div>
              </div>
            </DialogContent>
          </DialogOverlay>
        )}
        <div className="dashboard-container">
          <section className="header-section">
            <div className="dashboard-nav">
              <div className="nav-left">
                <a href="/">
                  <img className="stamp-image" src={companyLogo} />
                </a>
                <a href="/" className="logo-text">
                  Ask Me Something
                </a>
              </div>

              <div className="nav-right">
                {this.state.paidUser && (
                  <div className="nav-cancel-button">
                    <a
                      onClick={() =>
                        this.setState({ cancel_subscripiton_modal: true })
                      }
                      className="nav-text"
                    >
                      Cancel Subscription
                    </a>
                  </div>
                )}
                <a
                  href="mailto:viktor_atanasov@icloud.com"
                  className="nav-text"
                >
                  Support
                </a>
                <a onClick={this.signOut} className="nav-text">
                  Logout
                </a>
              </div>
            </div>
            <div className="dashboard-banner">
              <div className="dashboard-banner-text">
                <h1 className="dashboard-banner-title">
                  Welcome to your AMA dashboard! 👋
                </h1>
                <p className="dashboard-banner-subtitle">
                  Start collecting questions, by sharing a link to your public
                  dashboard or embedding it to your personal website.
                </p>
              </div>
              <div className="dashboard-button-container">
                <button onClick={this.openModal} className="button-widget">
                  <div className="button-widget-container">
                    <img src={imageLink} />
                    <span className="button-widget-text">Embed Widget</span>
                  </div>
                </button>
                <Link target="_blank" to={`/ama/${this.state.userId}`}>
                  <button className="button-dashboard">
                    <div className="button-dashboard-container">
                      <img src={imageShare} />
                      <span className="button-dashboard-text">
                        Public Dashboard
                      </span>
                    </div>
                  </button>
                </Link>
              </div>
            </div>
          </section>
          <section className="dashboard-section">
            <div className="name-container">
              <h1 className="dashboard-name">Dashboard</h1>
            </div>
            <div className="options-container">
              <a
                onClick={this.showQuestions}
                className={
                  this.state.showQuestions
                    ? "margin-right option selected-option"
                    : "margin-right option"
                }
              >
                Received
              </a>
              <a
                onClick={this.showAnswers}
                className={
                  this.state.showQuestions
                    ? "option "
                    : "option selected-option"
                }
              >
                Answered
              </a>
            </div>
          </section>
          <section className="question-section">
            {this.state.showQuestions ? (
              <div>
                {this.state.questions.length === 0 ? (
                  <QuestionAsked
                    placeholderBtn={true}
                    question={this.state.placeholderQuestion}
                    loading={this.state.loading}
                  />
                ) : (
                  this.state.questions.map((item) => (
                    <QuestionAsked
                      key={item._id}
                      userId={this.state.userId}
                      question={item}
                      refresh={this.refresh}
                      date={item.createdAt}
                      loading={this.state.loading}
                      deleteQuestionModal={this.deleteQuestionModal}
                    />
                  ))
                )}
              </div>
            ) : (
              this.state.answers.map((item) => (
                <QuestionAnswered
                  id={item._id}
                  question={item.question}
                  answer={item.answer}
                  key={item._id}
                  date={item.createdAt}
                  userId={this.state.userId}
                />
              ))
            )}
          </section>
          <section className="footer-section">
            <div className="footer-container">
              <div className="left-footer-box">
                <a className="nav-text">Ask Me Something</a>
                <h3 className="footer-subtext">
                  Built by
                  <a
                    href="https://twitter.com/viatanas"
                    target="_blank"
                    className="twitter-link"
                  >
                    @viatanas
                  </a>
                </h3>
              </div>
              <div className="central-footer-box">
                <div className="copyright-container">
                  <img src={copyrightLogo} />
                  <h3 className="copyright-text">2021</h3>
                </div>
                <h3 className="copyright-text-bottom">All Rights Reserved</h3>
              </div>

              <img src={companyLogo} />
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Dashboard;
