import React, { Component } from "react";
import "./Footer.css";
import copyrightIcon from "../../assets/copyright.svg";
import companyLogo from "../../assets/companyLogo.svg";

class Footer extends Component {
  state = {};
  render() {
    return (
      <section className="landing-footer-section">
        <div className="landing-footer-container">
          <div className="landing-footer-left">
            <h3 className="landing-title-text">Ask Me Something</h3>
            <h3 className="landing-subtitle-text">
              Built by
              <a
                href="https://twitter.com/viatanas"
                target="_blank"
                className="landing-twitter-link"
              >
                @viatanas
              </a>
            </h3>
          </div>
          <div className="landing-footer-center">
            <div className="landing-top-row">
              <img src={copyrightIcon} />
              <h3 className="landing-footer-year">2021</h3>
            </div>
            <h3 className="landing-footer-year">All Rights Reserved</h3>
          </div>
          <div className="landing-footer-right">
            <img src={companyLogo} />
          </div>
        </div>
      </section>
    );
  }
}

export default Footer;
