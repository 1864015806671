import React, { Component } from "react";
import "./QuestionAsked.css";
import penIcon from "../../assets/pen-icon.svg";
import binIcon from "../../assets/binIcon.svg";
import sendIcon from "../../assets/send-icon.svg";
import { format } from "timeago.js";
import { AuthContext } from "../authentication/Auth";

import ClipLoader from "react-spinners/ClipLoader";

class QuestionAsked extends Component {
  static contextType = AuthContext;

  // baseUrl = "http://localhost:4000";
  baseUrl = "https://ama-server-v1.herokuapp.com";

  state = {
    answer: "",
    toggleTextArea: false,
  };

  toggleOn = (e) => {
    e.preventDefault();
    this.setState({ toggleTextArea: true });
  };

  toggleOff = (e) => {
    e.preventDefault();
    this.setState({ toggleTextArea: false });
  };

  handleInputChange = (e) => {
    this.setState({ answer: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    fetch(
      // `http://localhost:4000/${this.props.userId}/answers/ama`,

      `${this.baseUrl}/${this.props.userId}/answers/ama`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          question: this.props.question,
          answer: this.state.answer,
          idToken: this.context.userToken,
        }),
      }
    ).then((_) => this.props.refresh());
  };

  handlePlaceholderSubmit = (e) => {
    e.preventDefault();
    this.setState({ answer: "" });
    this.setState({ toggleTextArea: false });
  };

  render() {
    return (
      <div>
        {this.props.loading ? (
          <div className="loading-wrapper">
            <ClipLoader
              size={35}
              color={"#1a202c"}
              loading={this.state.loading}
              speedMultiplier={1.5}
            ></ClipLoader>
          </div>
        ) : (
          <div>
            <div
              className={
                this.state.submitted ? "display-off" : "full-container"
              }
            >
              <form
                onSubmit={
                  this.props.placeholderBtn
                    ? this.handlePlaceholderSubmit
                    : this.handleSubmit
                }
              >
                <div className="top-box">
                  <div
                    className="binIcon-wrapper"
                    onClick={() =>
                      this.props.deleteQuestionModal(this.props.question)
                    }
                  >
                    <img src={binIcon} />
                  </div>
                  <div className="badge">
                    <h3 className="badge-text">NEW QUESTION</h3>
                  </div>
                  <div className="question">
                    <p className="question-text">
                      {this.props.question.question}
                    </p>
                  </div>
                  <div className="answer-box">
                    <textarea
                      required
                      value={this.state.answer}
                      onChange={this.handleInputChange}
                      placeholder="Type your answer here..."
                      className={
                        this.state.toggleTextArea
                          ? "textarea-answer"
                          : "display-off"
                      }
                    ></textarea>
                  </div>
                </div>
                <div className="bottom-box">
                  <div className="createdAt">
                    <h3 className="received-text">RECEIVED</h3>
                    <h3 className="date-text">{format(this.props.date)}</h3>
                  </div>
                  <div className="answer-button-box">
                    <button
                      onClick={this.toggleOff}
                      className={
                        this.state.toggleTextArea
                          ? "cancel-button"
                          : "display-off"
                      }
                    >
                      <span className="cancel-button-text">Cancel</span>
                    </button>
                    <button
                      type="submit"
                      className={
                        this.state.toggleTextArea
                          ? "send-button"
                          : "display-off"
                      }
                    >
                      <div className="button-inside">
                        <img className="send-icon" src={sendIcon} />
                        <span className="button-text">Send</span>
                      </div>
                    </button>
                    <button
                      type="button"
                      onClick={this.toggleOn}
                      className={
                        this.state.toggleTextArea
                          ? "display-off"
                          : "answer-button"
                      }
                    >
                      <div className="button-inside">
                        <img className="pen-icon" src={penIcon} />
                        <span className="button-text">Answer</span>
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default QuestionAsked;
