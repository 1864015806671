import React, { Component } from "react";
import "./Pricing.css";
import check from "../../assets/check.svg";

class Pricing extends Component {
  state = {};

  render() {
    return (
      <section className="pricing-section">
        <div id="pricing" className="pricing-container">
          <div className="pricing-title-container">
            <h1 className="pricing-title-text">Simple Pricing</h1>
            <p className="pricing-subtitle-text">
              Try out for free. Upgrade when needed.
            </p>
          </div>
          <div className="pricing-grid">
            <div className="pricing-element">
              <div className="pricing-element-inner">
                <h1 className="pricing-offer-title">Free</h1>
                <p className="pricing-offer-subtitle">
                  Start collecting questions instantly.
                </p>
                <div className="pricing-item">
                  <img src={check} />
                  <h3 className="pricing-item-text">
                    10 total answer submissions
                  </h3>
                </div>
              </div>
              <a className="pricing-buttonContainer" href="/signup">
                <button className="pricing-buy-button">
                  <span className="pricing-button-text">Get Started</span>
                </button>
              </a>
              <div className="pricing-underBtn-text">
                No card details required
              </div>
            </div>
            <div className="pricing-element">
              <div className="pricing-element-inner">
                <h1 className="pricing-offer-title">
                  $5<span className="month">/month</span>
                </h1>
                <p className="pricing-offer-subtitle">
                  Perfect for on-going use.
                </p>
                <div className="pricing-item">
                  <img src={check} />
                  <h3 className="pricing-item-text">
                    Unlimited answer submissions
                  </h3>
                </div>
              </div>

              <a className="pricing-buttonContainer" href="/signup">
                <button className="pricing-buy-button">
                  <span className="pricing-button-text">Get Started</span>
                </button>
              </a>
              <div className="pricing-underBtn-text">
                Cancel subscription anytime
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Pricing;
